.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.anchor {
  display: block;
  height: 80px;
  margin-top: -80px;
  visibility: hidden;

}

.anchor-about {
    display: block;
    height: -10px;
    margin-top: 0px;
    visibility: hidden;
}

.anchor-volunteer {
    display: block;
    height: -10px;
    margin-top: 0px;
    visibility: hidden;
}

@media only screen and (max-width: 500px)
{
    .anchor-about {
        display: block;
        height: 70px;
        margin-top: -70px;
        visibility: hidden;
    }

    .anchor-volunteer {
        display: block;
        height: 0px;
        margin-top: 0px;
        visibility: hidden;
    }
}
